import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import * as coursePlayerEnums from './lms-imscc-player.enum';
import { commonrequests } from "../commonHttprequest";
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SubSink } from 'subsink';
import { CustomLoaderService } from '../loader/custom-loader.service';
import { Subscription } from 'rxjs';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-lms-imscc-player',
  templateUrl: './lms-imscc-player.component.html',
  styleUrls: ['./lms-imscc-player.component.css']
})
export class LmsImsccPlayerComponent implements OnInit, OnDestroy {
  fcdapi = environment.fcdAPI;
  coursetitle = localStorage.getItem('courseName');
  qtitojson = '';
  imsccpath = '/';
  manifestLocation = localStorage.getItem('packagecdnlocation');
  cookie = '';
  usecookie = false;
  LastVisitedData=localStorage.getItem('lastvisitednode');
  coursePlayerEnums=coursePlayerEnums;
  baseUrl=environment.courseAPI;
  userUrl = environment.userAPI;
  flag = localStorage.getItem('flag');
  userRole=localStorage.getItem("lms.UserType");
  allNotes=[];
  private subs = new SubSink();
  assessmentBaseURL= environment.assessmentApi;
  courseSettings:any ={};
  showFcdPlayer:boolean = false;
  //For Auth token
  fcdAuthTokenApi = environment.fcdAuthTokenApi;
  client_id: string = environment.clientId;
  client_secret:string = environment.clientSecret;
  grant_type:string = environment.grantType;
  courseId: any;
  access_token:any;
  flagVar: any;
  flagVal: any;
  courseNameapi: any;
  courseNameValue: any;
  ApiValue:boolean = false;
  visitedNodes: [] = [];

 config = {
  cookie: '',
  usecookie: false,
  // cookie: 'https://lorapi-dev.learningmate.co/devlor/api/laravel/public/api/repository/setcookie',
  // usecookie: true,
  manifestLocation: this.manifestLocation,
  //imsccapi: 'https://adminapp-dev.learningmate.co/api/imscc',
  fcdapi: this.fcdapi,
  //fcdapi: 'https://uqcuf6idl7.execute-api.us-east-1.amazonaws.com/Prod/api/user/fcd',
  coursetitle: this.coursetitle,
  qtitojson: '',
  imsccpath: 'https://www.google.com',
 // assetlocation: 'https://adminapp-dev.learningmate.co',
  assetlocation: '',
  selectedNode: '',
  role: '',
  lms: false,
  launchurl: '',
  assessmentBaseURL:this.assessmentBaseURL,
  randomizeQuestion: false,
  userId:'',
  courseId:'',
  assessmentId:'',
  client_id: this.client_id,
  client_secret: this.client_secret,
  grant_type: this.grant_type,
  fcdAuthTokenApi: this.fcdAuthTokenApi,
  visitedNodes: this.visitedNodes
};

 toolConfigs = {
  env: {
    lorApiHost: 'https://lorapi-dev.learningmate.co/devlor/',
    adminHost: 'https://adminapp-dev.learningmate.co/',
    apiHostAuthServer: 'https://adminapp-dev.learningmate.co/'
  },
  config: {
    ltidata: {
      ltilink: '',
      customProperties: ''
    },
    tenantid: 'f4adbb5e-7dd4-467a-b1cd-0ecb4dd4217e',
    userid: '',
    lms: true,
    client_id: '',
    client_secret: '',
    libconfig: [
      { key: 'context_id', value: '1d6695d5a8c7ecdc826ba4506b539a8dacc2d41f' },
      { key: 'context_label', value: 'Niket External Tool' },
      { key: 'context_title', value: 'Niket External Tool' },
      { key: 'custom_canvas_enrollment_state', value: 'active' },
      { key: 'ext_roles', value: 'urn:lti:instrole:ims/lis/Instructor,urn:lti:role:ims/lis/Instructor,urn:lti:sysrole:ims/lis/User' },
      { key: 'launch_presentation_document_target', value: 'iframe' },
      { key: 'launch_presentation_locale', value: 'en' },
      {
        key: 'launch_presentation_return_url',
        value: 'https://learningmate.instructure.com/courses/804/external_content/success/external_tool_redirect'
      },
      { key: 'lti_message_type', value: 'basic-lti-launch-request' },
      { key: 'lti_version', value: 'LTI-1p0' },
      { key: 'oauth_callback', value: 'about:blank' },
      { key: 'resource_link_title', value: 'Intermediate Accounting, Ch. 2' },
      { key: 'roles', value: 'Instructor' },
      { key: 'tool_consumer_info_product_family_code', value: 'canvas' },
      { key: 'tool_consumer_info_version', value: 'cloud' },
      { key: 'tool_consumer_instance_contact_email', value: 'notifications@instructure.com' },
      { key: 'tool_consumer_instance_guid', value: 'O8k6qX3h098lny8QwQTcMwXA6f26vYUCymPplgii:canvas-lms' },
      { key: 'tool_consumer_instance_name', value: 'LearningMate' },
      { key: 'type', value: '31901e7d-b9b0-43f6-88cb-5c1a1df301e7' },
      { key: 'user_id', value: 'e6a12d2f375b0fe4abce76c0ea3664fb837a590b' }
    ]
  }
};
private routeSub: Subscription;
  constructor(private commonrequests:commonrequests, private router:Router, private http:HttpClient,  private loaderService: CustomLoaderService, private route:ActivatedRoute, private CommonService: CommonService,){ }

  ngOnInit() {
    //localStorage.removeItem('packagecdnlocation');
    this.routeSub =  this.route.params.subscribe( params =>{
      this.courseId=params['courseId'];
    });
    
    this.getCourses();
    this.config.userId = localStorage.getItem('lms.userId');
    this.config.courseId = localStorage.getItem('courseId');
  }

  getCourses() {
    let searchFilterData = {
      "userId":localStorage.getItem('lms.accountId'),
      "PageSize": 0,
      "PageNumber": 0,
      "courseId": this.courseId,
      "organizationId": environment.organizationId
    }
    this.loaderService.show();
    var accessToken = localStorage.getItem('lms.token');
    const url1 = `${environment.courseAPI}/course/getCoursesById`;
    this.CommonService.CallCourseIdApi(url1, accessToken , searchFilterData)
    .subscribe((response: any) => {
      localStorage.setItem('packagecdnlocation', response.data[0].FcdCdnPath);
      this.config.manifestLocation = response.data[0].FcdCdnPath;
      this.manifestLocation = this.manifestLocation ? this.manifestLocation: response.data[0].FcdCdnPath
        this.flagVar = response.data[0].Flag;
        this.courseNameapi = response.data[0].Name;
        this.config.coursetitle = this.courseNameapi;
        this.ApiValue = true;
        this.createQuiz();
  }, (error) => {
    console.error(error);
    if(error.status == 401 || error.status == 500) {
      this.loaderService.hide();
      localStorage.clear();
      localStorage.setItem('courseId',this.courseId);
      this.router.navigate(['/login-form'])
    }
  });
  }

  createQuiz() {
    if(localStorage.getItem('courseId') !== null){
      this.flagVal = this.flag ? this.flag : this.flagVar;
      this.config.courseId = localStorage.getItem('courseId') !== null? localStorage.getItem('courseId'): this.courseId;
      this.coursetitle = localStorage.getItem('courseName') !== null? localStorage.getItem('courseName') : this.courseNameapi;
    }else{
      this.flagVal = this.flagVar ? this.flagVar : this.flag;
      this.config.courseId = this.courseId ? this.courseId: localStorage.getItem('courseId');
      this.coursetitle = this.courseNameapi ? this.courseNameapi : localStorage.getItem('courseName');
    }
   let courseDetails={
    "UserId":localStorage.getItem('lms.accountId'),
    "CourseFcdPath":this.manifestLocation,
    //"FrostCourseId":localStorage.getItem('courseId'),
    "FrostCourseId": this.courseId,
    "UserRole":localStorage.getItem("lms.UserType"),
    "IsMandetory": this.flagVal,
  }
  
    let url=this.baseUrl + this.coursePlayerEnums.CoursePlayer.createQuiz;
    this.subs.sink = this.commonrequests.commonPostApi(url, courseDetails)
      .subscribe((data: any) => {
        if(this.ApiValue){
          this.showFcdPlayer = true;
        }
      }, (error) => {
        console.error(error);
        if(error.status == 401) {
          localStorage.clear();
          this.router.navigate(['/login-form'])
        }
      });
  }
  /**
   * If user is logged in and Hits the course ID url
   */
  // GetCourseIdApi(){
  //   const data = new HttpParams();
  //   this.routeSub =  this.route.params.subscribe( params =>{
  //     this.courseId=params['courseId'];
  //   });
  //   let searchFilterData = {
  //     "userId":localStorage.getItem('lms.accountId'),
  //     "PageSize": 0,
  //     "PageNumber": 0,
  //     "courseId": this.courseId,
  //     "organizationId": environment.organizationId
  //   }
  //   var accessToken = localStorage.getItem('lms.token');
  //   const url = `${environment.courseAPI}/course/getCoursesById`;
  //   this.CommonService.CallCourseIdApi(url, accessToken , searchFilterData)
  //   .subscribe((response: any) => {
  //     this.manifestLocation = response.data[0].FcdCdnPath;
  //   }, (error) => {
  //     console.error(error);
  //   });
  // }

  /* getSettings(){
    let data = {
      CourseId:  localStorage.getItem('courseId')
    }
    this.ngxUiLoaderService.start();
    this.commonrequests.commonPostApi(this.assessmentBaseURL + '/AssessmentPlayer/GetAssessMentConfigure', data)
      .subscribe((res:any)=>{
        if(res.statusCode === 200){
            this.courseSettings = res.Data;
            console.log('Trainefy courseSettings =', this.courseSettings)
            this.config.randomizeQuestion = this.courseSettings.randomize;
            this.config.assessmentId = this.courseSettings.assessmentId;
            this.config.userId = localStorage.getItem('lms.userId');
            this.config.courseId = localStorage.getItem('courseId');
            this.showFcdPlayer = true;
        }
        else if(res.statusCode === 404){
          this.showFcdPlayer = true;
        }
        this.loaderService.hide();
      },(error)=>{
        this.loaderService.hide();
        if(error.status == 401) {
          localStorage.clear();
          this.router.navigate(['/login-form'])
        }
      })
  } */

  TreeNodeClick(data){
    localStorage.setItem('lastvisitednode',data);
    if(localStorage.getItem('courseId') !== null){
      this.flagVal = this.flag ? this.flag : this.flagVar;
    }else{
      this.flagVal = this.flagVar ? this.flagVar : this.flag;
    }
    if(localStorage.getItem('courseName') !== null){
      this.courseNameValue = localStorage.getItem('courseName');
    }else{
      this.courseNameValue = this.courseNameapi;
    }
    let courseData={
      "UserId":localStorage.getItem('lms.accountId'),
      "ElementId":data,
      //"IsMandetory": localStorage.getItem('flag'),
      "IsMandetory": this.flagVal,
      "FrostCourseId":this.courseId + '@' + this.courseNameValue,
    };

    let url=this.baseUrl + this.coursePlayerEnums.CoursePlayer.userProgress;
    this.subs.sink = this.commonrequests.commonPostApi(url, courseData)
    .subscribe((res: any) => {
         if(res.success && res.CourseProgressStatus === 'Complete'){
             let flag = localStorage.getItem('flag');
             localStorage.setItem('flagForRating', flag);
             let courseId = this.courseId;
             localStorage.setItem('courseIdForRating', courseId);
         }
         this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      console.error(error);
      if(error.status == 401) {
        localStorage.clear();
        this.router.navigate(['/login-form'])
      }
    });
  };

  /* TreeNodeClick = function(data){
    console.log(data);
    localStorage.setItem('lastvisitednode',data);
    let url=this.baseUrl + this.coursePlayerEnums.CoursePlayer.userProgress;
    let courseData={
      "UserId":localStorage.getItem('lms.accountId'),
      "ElementId":data,
      "IsMandetory": localStorage.getItem('flag'),
      "FrostCourseId":localStorage.getItem('courseId') + '@' + localStorage.getItem('courseName'),
    };
    this.commonrequests.commonPostApi(url, courseData)
    .subscribe((data: any) => {
    console.log(data);
    }, (error) => {
      console.error(error);
    });
  }; */

  changeRoute(e){
      localStorage.removeItem('courseName');
      //this.router.navigate(['/']);
      const UserType = localStorage.getItem('lms.UserType');
      if (UserType == 'Student') {
        this.router.navigate(['student/home']);
      } else if (UserType == 'Admin') {
        this.router.navigate(['admin/home']);
      }
  }

/*   takePrint(e){
    var a:any = document.querySelector("body > app-root > app-lms-imscc-player > lm-fcd-player > lm-fcd-main").shadowRoot.querySelector("#print-section > lm-imscc-webcontent > lm-imscc-webcontent-home").shadowRoot.querySelector("div > lm-imscc-html > iframe");
    a.contentWindow.postMessage({'print':true}, '*');
  } */

  createNewNote(Notedata){
  let url=this.baseUrl+ this.coursePlayerEnums.CoursePlayer.createNote;
  let data={
  'UserId':localStorage.getItem('lms.accountId'),
  'FrostCourseId':localStorage.getItem('courseId'),
  'FrostCourseItemId':Notedata.openNode,
  'Title':Notedata.title,
  'Description':Notedata.description
  }
  this.subs.sink = this.commonrequests.commonPostApi(url, data)
  .subscribe((data: any) => {
    console.log(data);
   this.getAllNotes(Notedata.openNode);
  }, (error) => {
    console.error(error);
  });
  }

  getAllNotes(e){
    let url=this.baseUrl + this.coursePlayerEnums.CoursePlayer.getNotes +'/' + localStorage.getItem('lms.accountId') +'/' + localStorage.getItem('courseId') +'/'+ e;
    this.commonrequests.commomGetApi(url, null).subscribe((data:any)=>{
      this.allNotes= data.data;
    }, (error)=>{
      console.log(error);
    })
  }

  onEditNote(e){
    let url=this.baseUrl + this.coursePlayerEnums.CoursePlayer.editNotes;
    let data={
        'Id':e.id,
        'Title':e.title,
        'Description':e.description
    }
    this.subs.sink = this.commonrequests.commonPostApi(url, data)
    .subscribe((data: any) => {
      console.log(data);
     this.getAllNotes(e.openNode);
    }, (error) => {
      console.error(error);
    });
  }

  onNoteDelete(e){
    let url=this.baseUrl + this.coursePlayerEnums.CoursePlayer.deleteNotes;
    let data={
      'Id':e.note.Id,
      'FrostCourseId':localStorage.getItem('courseId'),
      'FrostCourseItemId':e.node,
      'UserId':localStorage.getItem('lms.accountId'),
    }
    this.subs.sink = this.commonrequests.commonPostApi(url, data)
    .subscribe((data: any) => {
      console.log(data);
     this.getAllNotes(e.node);
    }, (error) => {
      console.error(error);
    });

  }
  ngOnDestroy() {
    this.subs.unsubscribe();
    //this.routeSub.unsubscribe();
}
}
