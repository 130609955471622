import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificateModalComponent } from './certificate-modal.component';

@NgModule({
  declarations: [CertificateModalComponent],
  imports: [
    CommonModule
  ],
  exports: [CertificateModalComponent]
})
export class CertificateModalModule { }
