import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../_helpers/user';
import { Router } from '@angular/router';

// import {LocalstorageService } from './localstorage.service';
// import { DeactivateService } from './deactivate.service';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient,
        private router: Router,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(url, data) {
        const headers = new HttpHeaders({
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Basic T3JnYW5pemF0aW9uOm9zZWNyZXQ=',
        });
        const httpOptionsPost = {
            headers
        };
        return this.http.post<any>(url, data, httpOptionsPost)
            .pipe(map(user => {
                if (user.responseOutput && user.responseOutput.token) {

                    this.currentUserSubject.next(user.responseOutput);
                }
                return user;
            }));
    }



    getUserData(url, apiBody) {
        const headers = new HttpHeaders({
            Accept: '*/*',
            Authorization: 'Bearer ' + (!!localStorage.getItem('lms.token')
                ? localStorage.getItem('lms.token').replace(/"/g, '') : '')
        });
        const httpOptionsGet = {
            headers,
            params: apiBody
        };
        // let token = this.localstorageService.getLocalstorageData('LMS.token');
        // let url = environment.userAPI + LoginEnums.LoginWebApis.GetUserInfo;
        return this.http.get<any>(url, httpOptionsGet).pipe(map((res: any) => {
            if (res) {
                localStorage.setItem('userInfo', JSON.stringify(res))
                this.currentUserSubject.next(res);
            } else {
                this.currentUserSubject.next(null);
            }
            return res;
        }));
    }
    getUserInfo() {
        return this.currentUser;
    }
    setUserInfo(data) {
        this.currentUserSubject.next(data);
    }
    
    logoutUser() {
        localStorage.clear();
        this.router.navigate(['/']);

    }
}
