// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   production: false,

   /* ####################  For Dev ############################ */

       //For Auth token
      /* clientId: "d9d0a5c0-e933-4cc6-b2f2-9776b86f243e",
      clientSecret: "ZDlkMGE1YzAtZTkzMy00Y2M2LWIyZjItOTc3NmI4NmYyNDNl",
      grantType: "client_credentials",

      apiHostAuthServer:'https://oauth.trainefy.learningmate.com/oauth/',
      introspectapiHostAuthServer:'https://oauth-dev.trainefy.learningmate.com/oauth/connect/introspect',
      userAPI:'https://xtjs2t6wrj.execute-api.us-east-1.amazonaws.com/Prod/api',
      AdminUserApi: 'https://xtjs2t6wrj.execute-api.us-east-1.amazonaws.com/Prod/api',
      courseAPI:'https://zq32ph13tk.execute-api.us-east-1.amazonaws.com/Prod/api',
      fcdAPI: 'https://unifiedfrost-stage.learningmate.co/admin/api/fcd',
      newAdminUserApi: 'https://uqcuf6idl7.execute-api.us-east-1.amazonaws.com/Prod/api',
      courseESAPI:'https://uqcuf6idl7.execute-api.us-east-1.amazonaws.com/Prod/api',
      profileImageApi:'https://uqcuf6idl7.execute-api.us-east-1.amazonaws.com/Prod/api',
      notificationAPI:'https://xtjs2t6wrj.execute-api.us-east-1.amazonaws.com/Prod/api',
      googleOauthApi: 'https://oauth-dev.trainefy.learningmate.com/oauth/externalauth/googlelogin?provider=Google',
      organizationId: "1282eb3f-b4b7-42c0-8412-631c7900f6e3",
      assessmentApi:'https://zq32ph13tk.execute-api.us-east-1.amazonaws.com/Prod/api',
      fcdAuthTokenApi:'https://unifiedfrost-stage.learningmate.co/oauth/connect/token' */

      /* ####################  For QA ############################ */

       /*
        //For Auth token
        clientId: "d9d0a5c0-e933-4cc6-b2f2-9776b86f243e",
        clientSecret: "ZDlkMGE1YzAtZTkzMy00Y2M2LWIyZjItOTc3NmI4NmYyNDNl",
        grantType: "client_credentials",

        apiHostAuthServer:'https://oauth.trainefy.learningmate.com/oauth/',
        introspectapiHostAuthServer:'https://oauth-qa.trainefy.learningmate.com/oauth/connect/introspect',
        userAPI:'https://8bw5k904u1.execute-api.us-east-1.amazonaws.com/Prod/api',
        AdminUserApi:'https://4sewf1x5b5.execute-api.us-east-1.amazonaws.com/Prod/api',
        courseAPI:'https://4ul7aph565.execute-api.us-east-1.amazonaws.com/Prod/api',
        fcdAPI: 'https://unifiedfrost-stage.learningmate.co/admin/api/fcd',

        newAdminUserApi: 'https://s7gimv3ai1.execute-api.us-east-1.amazonaws.com/Prod/api',
        courseESAPI:'https://s7gimv3ai1.execute-api.us-east-1.amazonaws.com/Prod/api',

        profileImageApi:'https://s7gimv3ai1.execute-api.us-east-1.amazonaws.com/Prod/api',
        googleOauthApi: 'https://oauth-qa.trainefy.learningmate.com/oauth/externalauth/googlelogin?provider=Google',
        organizationId: "1282eb3f-b4b7-42c0-8412-631c7900f6e3",
        assessmentApi:'https://4ul7aph565.execute-api.us-east-1.amazonaws.com/Prod/api',
        fcdAuthTokenApi:'https://unifiedfrost-stage.learningmate.co/oauth/connect/token'
         */

     /* ####################  For Production ############################ */

      //For Auth token
      clientId: "ef0072a9-c7a1-46de-8ea6-9a0f0f190819",
      clientSecret: "gninraeletam",
      grantType: "client_credentials",

      apiHostAuthServer: 'https://oauth.trainefy.learningmate.com/oauth/',
      userAPI:'https://xkydjhfxxj.execute-api.us-east-1.amazonaws.com/Prod/api',
      notificationAPI:'https://fnty66addb.execute-api.us-east-1.amazonaws.com/Prod/api',
      courseAPI:'https://f873nq07zi.execute-api.us-east-1.amazonaws.com/Prod/api',
      introspectapiHostAuthServer:'https://oauth.trainefy.learningmate.com/oauth/connect/introspect',    
       AdminUserApi: 'https://mw4nss7igh.execute-api.us-east-1.amazonaws.com/Prod/api',
      fcdAPI:'https://frostplatform.com/admin/api/fcd',
      newAdminUserApi: 'https://mw4nss7igh.execute-api.us-east-1.amazonaws.com/Prod/api',
      courseESAPI:'https://mw4nss7igh.execute-api.us-east-1.amazonaws.com/Prod/api',
      profileImageApi:'https://admin-ui.trainefy.learningmate.com/api',
      googleOauthApi: 'https://oauth.trainefy.learningmate.com/oauth/externalauth/googlelogin?provider=Google',
      organizationId: "607376b4-c266-468e-b6e3-0037128c14f5",
      assessmentApi:'https://f873nq07zi.execute-api.us-east-1.amazonaws.com/Prod/api',
      //fcdAuthTokenApi:'https://unifiedfrost-stage.learningmate.co/oauth/connect/token'
      fcdAuthTokenApi:'https://frostplatform.com/oauth/connect/token',
      saasDomain:'https://frostplatform.com'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
