import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, isObservable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  islogin: BehaviorSubject<String>;
  loginData: any;
  menuu = new BehaviorSubject<string>("MandatoryCourse");
  menuType = this.menuu.asObservable();
  constructor(private http: HttpClient, private injector:Injector) {
    this.islogin = new BehaviorSubject<String>('');
    this.loginData = this.islogin.asObservable();

  }
  changeMessage(message: string){
    this.menuu.next(message)
  }

  SerializeData(data: any) {
    if (typeof data !== 'object') {
      return ((data == null) ? '' : data.toString());
    }
    const buffer = [];
    Object.entries(data).forEach(([key, value]) => {
      const a = encodeURIComponent(key) + '=' + encodeURIComponent((value === null) ? '' : value.toString());
      buffer.push(a);
    });

    const source = buffer.join('&').replace(/%20/g, '+');
    return (source);
  }

  AuthGetApi(url: any, apiBody: any) {
    const headers = new HttpHeaders({
      Accept: '*/*',
      Authorization: 'Bearer ' + (!!localStorage.getItem('lms.token')
        ? localStorage.getItem('lms.token').replace(/"/g, '') : '')
    });
    const httpOptionsGet = {
      headers,
      params: apiBody
    };
    return this.http.get(url, httpOptionsGet);
  }

  AuthPostApi(url: any, apiBody: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*',
    });
    const httpOptionsPost = {
      headers
    };
    return this.http.post(url, apiBody, httpOptionsPost);
  }
  LoginPostApi(url: any, apiBody: any) {
    const headers = new HttpHeaders({
      Accept: '*/*',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic T3JnYW5pemF0aW9uOm9zZWNyZXQ=',
    });
    const httpOptionsPost = {
      headers
    };
    return this.http.post(url, apiBody, httpOptionsPost);
  }
  authTokenApiMethod(url: any, apiBody: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*'
      })
    };
    return this.http.post(url, apiBody, httpOptions);
  }
  CallSetCookieMehod(url: any, apiBody: any, access_token:any) {
    const headers = new HttpHeaders({
      Accept: '*/*',
      Authorization: 'Bearer ' + access_token
    });
    const httpOptionsGet = {
      headers,
      params: apiBody,
      withCredentials: true
    };
    return this.http.get(url, httpOptionsGet);
  }
  CallCourseIdApi(url: any, access_token:any, requestParams) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        authorization: 'Bearer ' + access_token
      })
    };
    return this.http.post(url, requestParams, httpOptions);
  }
  // setLoginStatus(data) {
  //   if(data != ''){
  //     if(data.status== 400){
  //       localStorage.clear();
  //       sessionStorage.clear();
  //     }
  //     this.islogin.next(data)
  //   }
  // }
  // getLoginStatus(): Observable<any> {
  //   return this.loginData
  // }
}
