
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import * as dashboardEnums from '../../../app/student/lms-dashboard.enum';
import { environment } from '../../../environments/environment';
import { commonrequests } from "../../commonHttprequest";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {MessageService} from 'primeng/api';
import { ImageTransform } from 'src/app/student/interfaces/image-transform.interface';
import { SearchService } from '../search.service';
import { SubSink } from 'subsink';
declare let $: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers: [MessageService]
})
export class SearchComponent implements OnInit, OnDestroy {
  userName: any;
  dashboardEnums = dashboardEnums;
  allCourses: any = [];
  baseUrlP: any = environment.newAdminUserApi;
  userType: any;
  courseName: string;
  packageLocation: string;
  @ViewChild('fileInput') el: ElementRef;
  imageUrl: any = "";
  file: File = null;
  displayMode: any;
  profileImageBaseUrl: any = environment.profileImageApi;
  // crop image
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  user:any= {};
  badges: any = [];
  earnedBadges: any = [];
  private subs = new SubSink();
  disableBtn:boolean = false;
  searchKey:any;

  constructor(public searchService: SearchService,
    private AuthenticationService: AuthenticationService,
    private router: Router,
     private commonrequests: commonrequests,
      private cd: ChangeDetectorRef,
      private messageService: MessageService,
      private activatedRoute : ActivatedRoute,
      private elRef: ElementRef,
      private ref: ChangeDetectorRef) {
      }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
}

async ngOnInit() {
  this.activatedRoute.queryParamMap.subscribe(params => {
    this.searchKey = params.get('q');
  });
  if(this.searchKey!=''){
     await this.searchService.search(this.searchKey)
     this.allCourses = this.searchService.allCourses;
  }
  this.getUserProfile();
  this.displayMode = 2;
  this.userName = (sessionStorage.getItem('lms.firstName')) ? sessionStorage.getItem('lms.firstName') : '';
  this.userType=(localStorage.getItem('lms.UserType')) ? localStorage.getItem('lms.UserType') : '';
}
async searchData(data) {
  const searchData = data;
  if(searchData!=''){
     await this.searchService.search(searchData)
     this.allCourses = this.searchService.allCourses;
  }
}

/* ==================== Start Crop Image ============================== */

fileChangeEvent(event: any): void {
  if (event.target.files && event.target.files[0]) {
    if (event.target.files[0].type === 'image/jpeg' ||
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type ==='image/jpg') {
        /* checking size here - 1MB */
        if (event.target.files[0].size < 1000000) {
          this.imageChangedEvent = '';
          this.croppedImage = '';
          this.file = null;
          this.imageChangedEvent = event;
          this.file = event.target.files[0];
          $(document).ready(function(){
               $("#modalCropImage").modal("show");
           });
        }else{
          let errMmsg = 'Selected image must be lower than or equal to 1 mb';
          this.showError(errMmsg);
        }
    }
  }
}
saveCroppedImage(){
    this.disableBtn = true;
    let reader = new FileReader(); // HTML5 FileReader API
    reader.readAsDataURL(this.file);
    // When file uploads set it to file formcontrol
    reader.onload = () => {
      var formData: any = new FormData();
      formData.append('Image', this.file);
      formData.append('ImageBase64', this.croppedImage)
      let userId = localStorage.getItem('lms.userId');
      const url = this.profileImageBaseUrl+'/user/UpdateProfilePicTest?UserId=' + userId;
      this.subs.sink = this.commonrequests.cohortApi(url, formData)
      .subscribe((data : any) => {
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.file = null;
        this.disableBtn = false;
        let sucMmsg = 'Image Added Successfully';
        this.showSuccess(sucMmsg);
        this.getUserProfile();
        $(document).ready(function(){
          $("#modalCropImage").modal("hide");
        });
      },(error)=>{
          this.disableBtn = false;
          let errMmsg = 'Server Error';
          this.showError(errMmsg);
      });
    // ChangeDetectorRef since file is loading outside the zone
    this.cd.markForCheck();
  }
}

imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
}
resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
}
zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}
zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}
imageLoaded() {
  /* show cropper */
}
cropperReady() {
    /* cropper ready */
}
loadImageFailed() {
    /* show message */
}

/* ==================== End Crop Image ============================== */

homeRedirect() {
    const UserType = localStorage.getItem('lms.UserType');
    if (UserType == 'Student') {
      this.router.navigate(['student/home']);
    } else if (UserType == 'Admin') {
      this.router.navigate(['admin/home']);
    }
}
getUserProfile() {
  let userId = localStorage.getItem('lms.userId');
  const url = `${this.baseUrlP}${this.dashboardEnums.DashboardWebApis.userProfile}/${userId}`;
  this.subs.sink = this.commonrequests.commomGetApi(url, null)
  .subscribe((res : any) => {
    if(res.statusCode === 200){
      this.user = res.Data.profileDetails;
      this.badges = res.Data.Badges;
      this.earnedBadges = res.Data.EarnedBadges;
      this.imageUrl = res.Data.profileDetails.ImageBase64;
    }
  })
}

keyCode(event: any) {
  let backHover = this.el.nativeElement.querySelectorAll('.card-hover');
  for(let i=0; i < backHover.length; i++) {
    //backHover[i].style.display = "none";
    backHover[i].setAttribute('aria-expanded', 'false');
  }
  let cardHoverElement = event.target.parentElement.parentElement.parentElement.querySelector('.card-hover');
  cardHoverElement.style.display = "block";
  cardHoverElement.setAttribute('aria-expanded', 'true');
}

openPlayer(packagecdnlocation, courseName, courseId, lastvisitednode){
  localStorage.setItem('courseName', courseName);
  localStorage.setItem('packagecdnlocation', packagecdnlocation);
  localStorage.setItem('courseId',courseId);
  localStorage.setItem('lastvisitednode',lastvisitednode);
  localStorage.setItem('flag', this.allCourses[0].Flag);
 // this.router.navigate(['lmsplayer']);
 this.router.navigate([`lmsplayer/${courseId}`]);
}

OpenPlayer(node, courseId){
  localStorage.setItem('lastvisitednode', node);
  localStorage.setItem('courseName', this.courseName);
  localStorage.setItem('packagecdnlocation', this.packageLocation);
  localStorage.setItem('courseId',courseId);
  localStorage.setItem('flag', this.allCourses[0].Flag);
  //this.router.navigate(['lmsplayer']);
  this.router.navigate([`lmsplayer/${courseId}`]);
}
gradeRedirect() {
  this.router.navigate(['/gradebook']);
}
calendarRedirect() {
  this.router.navigate(['/calendar']);
}
forumRedirect() {
  this.router.navigate(['/google-form']);
}
LogOut() {
  this.AuthenticationService.logoutUser();
}
showSuccess(msg) {
  this.messageService.add({
      severity:'success',
      summary: 'Success',
      detail: msg
    });
}
showError(msg) {
   this.messageService.add({
      severity:'error',
      summary: 'Error',
      detail: msg
    });
}
ngOnDestroy() {
  this.subs.unsubscribe();
}
skipToMainContent(){
  const htmlElement = this.elRef.nativeElement.querySelector('#homePage .col-12');
  if (htmlElement) {
      htmlElement.setAttribute('tabindex','0');
      htmlElement.focus();
  }
  this.ref.detectChanges();
}
}
