<form ngNativeValidate role="search" #f="ngForm" class="form-inline my-2 my-lg-0" (ngSubmit)="onSubmit(f)" novalidate="novalidate">
    <div class="input-group search-area">
        <input type="text" ngModel name='search' placeholder="Search" aria-label="Search" (keyup)="invalidDetails" autocomplete="off" pattern="^[A-Za-z0-9\s]+" class="form-control search border-left-0" [ngClass]="{ 'is-invalid': submitted && f.search.errors }"
            aria-invalid="false" aria-required="false" />
        <div class="input-group-append">
            <button class="btn btn-search" type="submit" aria-label="Search" (click)=search()>
                        <em class="material-icons search-icon" aria-hidden="true">search</em>
                    </button>
        </div>
    </div>
</form>
