import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CustomLoaderService } from "./custom-loader.service";

@Component({
  selector: 'custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.css']
})
export class CustomLoaderComponent implements OnInit, OnDestroy {

  public destroy$: Subject<boolean> = new Subject<boolean>();
  public show = false;

  constructor(private loaderService: CustomLoaderService) { }

  ngOnInit() {
    this.loaderService.getLoaderState()
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(state => {
      this.show = state.show;
      this.setScrollBarVisibility(!this.show);
    });
  }

  private setScrollBarVisibility(visible: boolean) {
    document.body.style.overflow = visible ? 'auto' : 'hidden';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
