<p-toast></p-toast>
<!--Navbar HTML starts-->
<header class="navbar navbar-light bg-light navbar-expand-md">
    <a class="sr-only sr-only-focusable skiptomain" href="JavaScript:void(0);" (click)="skipToMainContent()">Skip to main content</a>
    <a class="navbar-brand" href="JavaScript:void(0);" (click)="homeRedirect()"><img alt="Trainefy Product Logo" class="logo" src="../../assets/images/Trainefy-logo.svg"><span class="sr-only">Home Page</span></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarToggler">
        <app-search-bar (demo)="searchData($event)"></app-search-bar>
        <div class="navbar-nav" [style.display]='this.userType === "Student" ?"block":"none"'>
            <div class="nav-item">
                <button type="button" class="nav-button btn-trans" (click)="calendarRedirect()">
                    <img src="../../assets/images/Calendar-icon.svg" class="menu-icon"  alt="" aria-hidden="true">
                    <span class="sr-only">Calendar</span>
                </button>
            </div>
        </div>
        <div class="navbar-nav">
            <div class="nav-item dropdown profileDropdown">
                <button type="button" class="nav-button dropdown-toggle btn-trans" id="DropdownMenuProfile" data-toggle="dropdown" aria-expanded="false" aria-label="Profile:">
             <img [src]="imageUrl ? imageUrl: '../../assets/images/UserProfile-icon.svg'" class="menu-icon" alt="" aria-hidden="true">
             <span class="sr-only">{{userName}}</span>
             </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="DropdownMenuProfile">
                    <div>
                        <div class="namerole">
                            <p class="mb-0"><img src="../../assets/images/Profile-icon.svg" tabindex="0" alt="" aria-hidden="true" aria-label="Profile">Profile</p>
                        </div>
                        <div class="row profile-row">
                            <div class="col-12 course-div profile-div">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="card-body-lft">
                                            <div class="card-imgholder">
                                                <img class="card-img-top" id="imagePreview" [src]="imageUrl ? imageUrl: '../../assets/images/UserProfile-icon.svg'">
                                            </div>
                                            <div class="profile-uploader">
                                                <div class="file-upload">
                                                    <input type='file' accept=".png, .jpg, .jpeg" (change)="fileChangeEvent($event)" />
                                                    <img src="../../assets/images/Camera-icon.svg" alt="" aria-hidden="true">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body-rgt">
                                            <div>
                                                <p class="mb-2" tabindex="0">Name: <span class="text-capitalize">{{user.UserName}}</span></p>
                                                <p class="mb-2" tabindex="0">Employee Id: {{user.EmployeeID}}</p>
                                                <p class="mb-2" tabindex="0">Designation: {{user.Designation}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row profile-row mt-1">
                            <div class="col-12">
                                <div class="profile-reward" tabindex="0">
                                    <div class="profile-reward-lft">
                                        <span class="font-weight-bold">My Points</span>
                                    </div>
                                    <div class="profile-reward-rgt">
                                        <span class="font-weight-bold font18">0</span> <span class="font13 ml-1">Overall Points</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row profile-row">
                            <div class="col-12">
                                <div class="mt-2 mb-1 font-weight-bold font18 d-flex justify-content-between align-items-center">My Badges
                                    <h3><a href="#modalBadges" data-toggle="modal" class="defi-badges"> Definition of Badges</a></h3>
                                </div>
                            </div>
                        </div>

                        <div class="row profile-row badge-container mt-2" *ngIf="badges!=null ; else elseBlock">
                            <ng-container *ngFor="let data of badges">
                                <div *ngIf="data.BatcheName!=''" class="col-3 profile-col text-center font13 mb-4">
                                    <div class="profile-badges">
                                        <img [src]="'../../assets/badges/'+data.badgeImage+'.svg'" alt=""></div>
                                    <p class="mb-0 mt-2" tabindex="0">{{data.BatcheName}}</p>
                                </div>
                            </ng-container>
                        </div>
                        <ng-template #elseBlock>
                            <p class="text-center mt-2" tabindex="0"> No badges earned!</p>
                        </ng-template>
                        <div class="row profile-row" *ngIf="earnedBadges!=null">
                            <div class="col-12">
                                <div class="mb-2 font-weight-bold font18 d-flex justify-content-end align-items-center">
                                    <a href="#modalEarnedBadges" data-toggle="modal" class="defi-badges"> Earned Badges</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-divider"></div>
                    <a href="JavaScript:void(0);" class="dropdown-item" (click)="gradeRedirect()" [style.display]='this.userType === "Student" ?"block":"none"'><img src="../../assets/images/Gradebook-icon.svg" alt="" aria-hidden="true" style="width:17px;"> My Gradebook</a>
                    <div class="dropdown-divider"></div>
                    <a href="JavaScript:void(0);" class="dropdown-item" tabindex="0" [style.display]='this.userType === "Student" ?"block":"none"'><img src="../../assets/images/Reports-icon.svg" alt="" aria-hidden="true"> Reports</a>
                    <div class="dropdown-divider" [style.display]='this.userType === "Student" ?"block":"none"'></div>
                    <a href="JavaScript:void(0);" class="dropdown-item" tabindex="0" [style.display]='this.userType === "Student" ?"block":"none"'><img src="../../assets/images/Issue-icon.svg" alt="" aria-hidden="true"> Report an Issue</a>
                    <a href="JavaScript:void(0);" class="dropdown-item" (click)='LogOut()' tabindex="0"><img src="../../assets/images/Signout-icon.svg" alt="" aria-hidden="true" height="20px"> Sign Out</a>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- search -->
<ng-template [ngIf]="searchService.totalRecords == 0" aria-live="true" [ngIfElse]='show' id="notificationTmpl">
    <section class="mainSection" role="main" id="homePage">
        <div class="container-fluid">
            <div class="row row-new">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <div class="search-div" role=alert>
                        <img src="../../../assets/images/search-result-icon.png" class="search-icon" aria-hidden="true" />
                        <h2 class="search-head" tabindex="0">Sorry we couldn't find any matches</h2>
                        <p class="search-text mb-0" tabindex="0">Trying changing the filter or search term </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>
<ng-template #show>
    <section class="mainSection" role="main" id="homePage">
        <div class="container-fluid">
            <div class="row row-new">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <button class="btn-trans btn-pad0" aria-label="Home" routerLink="/">
                <img src="../../assets/images/Home-icon.svg" class="home-icon" alt="" aria-hidden="true">
                </button>
                        </div>
                        <div class="col-6 text-right">
                            <button class="btn-trans btn-pad0 mr-3" type="button" (click)="onDisplayModeChange(2)" [ngClass]="{'active': displayMode === 2}" aria-label="Courses in Grid View">
                <img src="../../assets/images/Grid-icon.svg" class="gridview-icon" alt="" aria-hidden="true" *ngIf="displayMode === 1">
                <img src="../../assets/images/Grid_Active-icon.svg" class="gridview-icon" alt="" aria-hidden="true" *ngIf="displayMode === 2">
                </button>
                            <button class="btn-trans btn-pad0" type="button" (click)="onDisplayModeChange(1)" [ngClass]="{'active': displayMode === 1}" aria-label="Courses in List View">
                <img src="../../assets/images/List-icon.svg" class="listview-icon" alt="" aria-hidden="true" *ngIf="displayMode === 2">
                <img src="../../assets/images/List_Active-icon.svg" class="listview-icon" alt="" aria-hidden="true" *ngIf="displayMode === 1">
                </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-new mt-2">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="row row-new mt-2 mb-4">
                <div class="col-12">
                    <h2 class="course-title">Courses <span class="having">({{searchService.totalRecords}})</span>
                    </h2>
                </div>
            </div>
            <!--With Dynamic Data Grid-->
            <div *ngIf="displayMode === 1" class="row row-new course-div courselist-div w-auto" role="list" role=alert>
                <ng-container *ngFor="let course of searchService.allCourses">
                    <div class="col-12" role="listitem">
                        <div class="row">
                            <div class="col-12 col-sm-5 col-md-4 col-lg-3">
                                <div class="card mb-4">
                                    <img class="card-img-top" loading="lazy" src="{{course.CoverImage}}" alt="">
                                    <div class="card-body">
                                        <div class="timegrade list-inline">
                                            <span class="list-inline-item" aria-label="Course Duration"><span class="sr-only">Course Duration:</span> {{course.seatTime}} mins </span>
                                            <span class="list-inline-item" aria-hidden="true">|</span>
                                            <span>
                                            <star-rating [value]="course.rating" [totalstars]="5" checkedcolor="#ffe135" uncheckedcolor="#dddddd" size="18px" readonly="true" aria-hidden="true"></star-rating><span class="sr-only">{{course.rating}} star</span>
                                            </span>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" [ngStyle]="{'width':course.Progress +'%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-7 col-md-8 col-lg-9">
                                <div class="w-100 h-100 mb-4">
                                    <h3 class="card-title-list">{{course.Name}}</h3>
                                    <p class="card-sub-list">Category: Content</p>
                                    <p>{{course.description}} </p>
                                    <div>
                                        <button *ngIf='course.Progress == 0' class="btn-course" (click)="openPlayer(course.FcdCdnPath, course.Name, course.FrostElementId, course.LastVisitedItemId)" attr.aria-label="{{course.Name}} Start Course">
                               Start
                               </button>
                                        <button *ngIf='course.Progress > 0 && course.Progress < 100' class="btn-course" (click)="openPlayer(course.FcdCdnPath, course.Name, course.FrostElementId, course.LastVisitedItemId)" attr.aria-label="{{course.Name}} Continue Course">
                               Continue
                               </button>
                                        <button *ngIf='course.Progress == 100' class="btn-retake" (click)="openPlayer(course.FcdCdnPath, course.Name, course.FrostElementId, course.LastVisitedItemId)" attr.aria-label="{{course.Name}} Retake Course">
                               Retake
                               </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="displayMode === 2" class="row row-new course-div w-auto" role=alert>
                <ng-container *ngFor="let course of searchService.allCourses let cardIndex = index">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 course-mrbt col-new nocarousel">
                        <div class="card" tabindex="0" (click)="keyCode($event)">
                            <div class="card-inner w-100 h-100">
                                <img class="card-img-top" loading="lazy" src="{{course.CoverImage}}" alt="">
                                <div class="card-body">
                                    <h3 class="card-title">{{course.Name}}</h3>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" [ngStyle]="{'width':course.Progress +'%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-hover" aria-live="polite">
                                <div class="card-hover-in">
                                    <div class="card-title-hover">{{course.Name}}</div>
                                    <div class="timegrade list-inline">
                                        <span class="list-inline-item" aria-label="Course Duration"><span class="sr-only">Course Duration:</span> {{course.seatTime}} mins </span>
                                        <span class="list-inline-item" aria-hidden="true">|</span>
                                        <span>
                                        <star-rating [value]="course.rating" [totalstars]="5" checkedcolor="#ffe135" uncheckedcolor="#dddddd" size="18px" readonly="true" aria-hidden="true"></star-rating><span class="sr-only">{{course.rating}} star</span>
                                        </span>
                                    </div>
                                    <p class="card-text-hover">{{course.description}} </p>
                                    <div class="card-hover-foot">
                                        <div>
                                            <button *ngIf='course.Progress == 0' class="btn-course" (click)="openPlayer(course.FcdCdnPath, course.Name, course.FrostElementId, course.LastVisitedItemId)" attr.aria-label="{{course.Name}} Start Course">
                                  Start
                                  </button>
                                            <button *ngIf='course.Progress > 0 && course.Progress < 100' class="btn-course" (click)="openPlayer(course.FcdCdnPath, course.Name, course.FrostElementId, course.LastVisitedItemId)" attr.aria-label="{{course.Name}} Continue Course">
                                  Continue
                                  </button>
                                            <button *ngIf='course.Progress == 100' class="btn-retake" (click)="openPlayer(course.FcdCdnPath, course.Name, course.FrostElementId, course.LastVisitedItemId)" attr.aria-label="{{course.Name}} Retake Course">
                                  Retake
                                  </button>
                                        </div>
                                        <div class="course-status text-nowrap">
                                            <span *ngIf='course.Progress == 100'><em class="material-icons" aria-hidden="true">check_circle</em>
                                  Completed</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>
</ng-template>
<footer class="footer">
    <div class="footer-inside">
        <span class="lastlogin">Last Login : {{user.LastLoginDate | date: "MM/dd/yyyy"}} &nbsp;&nbsp;{{user.LastLoginDate | date: "hh:mm:ss a"}}</span>
        <span> Powered by
        <span class="footer-img1"><img src="../../assets/images/frost_logo@2x.png" alt="Frost Logo" /></span>
        <span class="footer-img2"><img src="../../assets/images/LearningMate-logo.svg" alt="LearningMate Logo" /></span>
        </span>
    </div>
</footer>

<!-- Crop Image Modal -->
<div class="modal fade" id="modalCropImage" tabindex="-1" role="dialog" aria-labelledby="modalCropImageTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pt-3 pb-3">
                <h1 class="modal-title"> Cropping Profile Image </h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <img src="../../assets/images/Close-icon.svg" aria-hidden="true" alt="">
            </button>
            </div>
            <div class="modal-body">
                <div class="w-100">
                    <div class="row">
                        <div class="col-12 col-sm-10 offset-sm-1">
                            <div class="cropimg-container d-flex align-items-center justify-content-center">
                                <div class="row">
                                    <div class="text-center col-12">
                                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="165" roundCropper=true format="png" [transform]="transform" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer cropimg-footer  pt-2 d-flex align-items-center justify-content-between">
                <div class="btn-cropimg-wrapper">
                    <button class="btn-crop">Change Photo</button>
                    <input type='file' accept=".png, .jpg, .jpeg" (change)="fileChangeEvent($event)" />
                </div>
                <span aria-hidden="true" class="mr-1 ml-1">|</span>
                <button class="btn-crop" (click)="resetImage()">Reset Photo</button>
                <span aria-hidden="true" class="mr-1 ml-1">|</span>
                <button class="btn-crop" (click)="zoomOut()"><em class="fa fa-minus-circle" aria-hidden="true"></em></button> <span aria-hidden="true" class="mr-1 ml-1">|</span>
                <button class="btn-crop" (click)="zoomIn()"><em class="fa fa-plus-circle" aria-hidden="true"></em></button>
                <button class="btn-course pb-2 pt-2" [disabled]="disableBtn" (click)="saveCroppedImage()">Apply</button>

            </div>
        </div>
    </div>
</div>
<!--New Modal Badges-->
<div class="modal fade" id="modalBadges" tabindex="-1" role="dialog" aria-labelledby="modalBadgesTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title"> Definition of Badges </h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<img src="../../assets/images/Close-icon.svg" aria-hidden="true" alt="">
				</button>
            </div>
            <div class="modal-body">
                <div class="w-100">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="badge-modal-tile mb-3">Eligibility</h2>
                        </div>
                        <div class="col-12">
                            <h3 class="mb-3">Process Training </h3>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-8 pl-2new">
                                    <h4 class="mb-1 font-weight-bold">Super Learner</h4>
                                    <p class="mb-0">If the participants can complete the mandatory process trainings within 48 hours.</p>
                                </div>
                                <div class="col-4 d-flex align-items-center">
                                    <div class="badge-div">
                                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/SuperLearner.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-8">
                                    <h4 class="mb-1 font-weight-bold">Learning Star</h4>
                                    <p class="mb-0">After completion of each process training participant will receive badge.</p>
                                </div>
                                <div class="col-4 d-flex align-items-center">
                                    <div class="badge-div">
                                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/LearningStar.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <h3 class="mb-3">
                                <h3 class="mb-3">Assigned Training </h3>
                            </h3>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-8 pl-2new">
                                    <h4 class="mb-1 font-weight-bold">Valiant</h4>
                                    <p class="mb-0">If a training has been assigned to a group of participants, badges for the first 3 participants will be allocated based on the first completion basis.</p>
                                </div>
                                <div class="col-4 d-flex align-items-center">
                                    <div class="badge-div">
                                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/Valiant.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-8">
                                    <h4 class="mb-1 font-weight-bold">Zealous</h4>
                                    <p class="mb-0">If the employees complete the assigned training in stipulated time frame then he/she will be awarded with a badge.</p>
                                </div>
                                <div class="col-4 d-flex align-items-center">
                                    <div class="badge-div">
                                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/Zealous.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <h3 class="mb-3">Trainefy Self Learning - Number of hours basis </h3>
                            <!--<p>Content comes here...</p>-->
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-between">
                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/1Hr.svg">
                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/7Hr.svg">
                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/14Hr.svg">
                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/21Hr.svg">
                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/28Hr.svg">
                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/35Hr.svg">
                        <img alt="" style="width:90px; height: auto;" src="../../assets/images/56Hr.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Earned Badges-->
<div class="modal fade" id="modalEarnedBadges" tabindex="-1" role="dialog" aria-labelledby="modalEarnedBadgesTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title"> Earned Badges</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <img src="../../assets/images/Close-icon.svg" aria-hidden="true" alt="">
        </button>
            </div>
            <div class="modal-body p-2">
                <div class="w-100">
                    <div class="row row-border" *ngFor="let eBadges of earnedBadges">
                        <div class="col-4 d-flex align-items-center justify-content-center">
                            <img [src]="'../../assets/badges/'+eBadges.badgeImage+'.svg'" alt="">
                        </div>
                        <div class="col-8 d-flex align-items-center">
                            <div class="w-100">
                                <p *ngFor="let item of eBadges.earnedBadges">
                                    {{item.courseName}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>