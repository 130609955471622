export enum DashboardWebApis {
    changePassword='/user/changepassword',
    courseInfo='/course/getAllCourses',
    courseTags='/course/coursetags',
    inProgressCourseInfo='/course/inprogressusercourses',
    mandatoryCourseInfo='/course/mandatorycourses',
    recommendedCourseInfo = '/course/getrecommendedCourses',
    getGlobalSearch='/course/globalsearch',
    getReport = '/user/GetStudentReport',
    userProfile= '/user/userprofile',
    ReportAnIssue = '/user/ReportAnIssue',
    programByUser ='/Programs/GetProgramByUser',
    programCoursesByUser = '/course/GetprogramCoursesByUsers',
    ratingContent = '/rating/GetRatingContent',
    setRatingContent = '/rating/SetRatingContent',
    getBanner = '/Banner/getAllBanner',
    getUpcomingCourses = '/Banner/getUpcomingCourses',
    getFeaturedCourse = '/course/GetFeaturedCourse',
    getMandatoryCourses = '/course/getMandatoryUserCourses',
    getAllocatedCourses = '/course/getAllocatedCourses',
    inprogressusercourses = '/course/inprogressusercourses',
    getCompletedCourses = '/course/getCompletedCourses',
    getStudentReportYearList = '/Report/getStudentReportYearList',
    getStudentReport = '/Report/getStudentReport',


    ListAllBanner = '/Banner/ListAllBanner',
}
