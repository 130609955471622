import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  Accept: '*/*'

  // authorization: 'bearer ' + localStorage.getItem('adminportal.token').replace(/"/g, ''),
  // refreshtoken: localStorage.getItem('adminportal.refreshtoken').replace(/"/g, '')
});
@Injectable({
  providedIn: 'root'
})
export class commonrequests {
  getPaginatedData(): any[] {
    throw new Error("Method not implemented.");
  }
  private pageData: BehaviorSubject<String>;
  pageName: any;
  loginPagesData: any = [
    {
      'login': {
        'heading': 'Hello! Welcome Back',
        'subtext': 'Login to continue to our application.',
      },
      'forgetpassword': {
        'heading': 'Don\'t worry!',
        'subtext': 'Just enter your email and we\'ll send you a reset password link.',
      },
      'resetpassword': {
        'heading': 'Hello, Friend!',
        'subtext': 'The new password must contain minimum 8 characters.',
      },
      'mailSent': {
        'heading': 'Check in your mail!',
        'subtext': 'We just emailed you with instructions to reset your password.',

      },
      'resetSuccess': {
        'heading': 'Hello, Friend!',
        'subtext': 'You can now use your new password to log in to your account.',
      },
      'link-expired': {
        'heading': 'Hello! Welcome Back',
        'subtext': 'Login to continue to our application.'
      }
    }];

  constructor(private http: HttpClient) {
    this.pageData = new BehaviorSubject<String>('');
    this.pageName = this.pageData.asObservable();
  }

  public cohortDetails = new BehaviorSubject<any>([]);
  observeCohortDetails$ = this.cohortDetails.asObservable();

  GetHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (!!localStorage.getItem('lms.token')
        ? localStorage.getItem('lms.token').replace(/"/g, '') : ''),
    });
    // return new HttpHeaders();
  }

  commomGetApi(path: any, params: any) {
    const httpOptionsGet = {
      headers: (localStorage.getItem('lms.token')) ? this.GetHeaders() : { 'Content-Type': 'application/json' },
      params
    };
    return this.http.get(path, httpOptionsGet);
  }

  commonPostApi(url: any, apiBody: any): Observable<object> {
    const httpOptionsPost = {
      headers: (localStorage.getItem('lms.token')) ? this.GetHeaders() : { 'Content-Type': 'application/json' }
    };
    return this.http.post(url, apiBody, httpOptionsPost);
    // return this.http.post(url, apiBody, httpOptionsPost).pipe(
    //   retry(3));
  }

  commomGetApiWithoutToken(path: any, params: any) {
    const httpOptionsGet = {
      headers: { 'Content-Type': 'application/json' }
    };
    return this.http.get(path, httpOptionsGet);
  }
  commonPostApi2(url: any, apiBody: any): Observable<object> {
    const httpOptionsPost = {
      headers: { 'Content-Type': 'application/json' }
    };
    return this.http.post(url, apiBody, httpOptionsPost);
  }
  courseData(url: any, apiBody: any): Observable<object> {
    const httpOptionsPost = {
      headers: (localStorage.getItem('lms.token')) ? this.GetHeaders() : { 'Content-Type': 'application/json' }
    };
    //  return this.http.post(url, apiBody, httpOptionsPost);
    return this.http.post(url, apiBody, httpOptionsPost);
  }

  createUserPostApi(url: any, apiBody: any): Observable<object> {
    const httpOptionsPost = {
      headers: (localStorage.getItem('lms.token')) ? this.GetHeaders() : { 'Content-Type': 'application/json' }
    };
    return this.http.post(url, apiBody, httpOptionsPost);
  }

  userProfileApi(url: any, apiBody: any): Observable<object> {
    const httpOptionsPost = {
      headers: (localStorage.getItem('lms.token')) ? this.GetHeaders() : { 'Content-Type': 'application/json' }
    };
    return this.http.post(url, apiBody, httpOptionsPost);
  }

  cohortApi(url: any, apiBody: any): Observable<object> {
    return this.http.post(url, apiBody);
  }

  downloadExcelApi(url: any, apiBody: any): Observable<object> {
    return this.http.post(url, apiBody,
      { responseType: 'blob' }
    );
  }

  cohortGetApi(url: any, apiBody: any) {
    return this.http.post(url, apiBody).pipe(
      retry(3));
  }

  programApi(url: any, apiBody: any): Observable<object> {
    //return this.http.post(url, apiBody);
    const httpOptionsPost = {
      headers: (localStorage.getItem('lms.token')) ? this.GetHeaders() : { 'Content-Type': 'application/json' }
    };
    return this.http.post(url, apiBody, httpOptionsPost);
  }


  commonPutApi(url: any, apiBody: any) {
    const httpOptionsPost = {
      headers: (localStorage.getItem('lms.token')) ? this.GetHeaders() : { 'Content-Type': 'application/json' }
    };
    return this.http.put(url, apiBody, httpOptionsPost);
  }

  setPageData(name) {
    if (name != " ") {
      this.pageData.next(name);
    }
  }

  getPageData(): Observable<any> {
    return this.pageName;
  }
}
