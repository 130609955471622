import { HttpClientModule } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BlockCopyPasteDirective } from './account/block-copy-paste.directive';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { SafeHtml } from './core/pipes/safeHtml';
import { LmsImsccPlayerComponent } from './lms-imscc-player/lms-imscc-player.component';
import { SharedModule } from './shared/shared.module';
import { SelectedTag } from './_helpers/sortTags.pipe';
import { ImsccPlayerModule } from 'imscc-player';
import { FcdPlayerModule } from 'fcd-player';
import { SessionTimeoutService } from './core/services/session-timeout.service';
import { SearchModule } from './search';
import { CustomLoaderComponent } from './loader/custom-loader.component';
import { TableModule } from 'primeng/table';
import {DatePipe} from '@angular/common';
//import { UnderMaintenanceComponent } from './default-pages/under-maintenance/under-maintenance.component';

@NgModule({
  declarations: [
    AppComponent,
    SafeHtml,
    SelectedTag,
    BlockCopyPasteDirective,
    LmsImsccPlayerComponent,
    CustomLoaderComponent,    
   // UnderMaintenanceComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SearchModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled'
    }),
    CoreModule,
    SharedModule,
    FcdPlayerModule,
    ImsccPlayerModule,
    TableModule
  ],
  exports: [
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [Title,SessionTimeoutService, CookieService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
