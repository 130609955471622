import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CommonService } from './common.service';
import { SessionTimeoutService } from './core/services/session-timeout.service';
// import {LoaderService} from './loader/loader.service';
// import {Loader} from './loader/loader.model';
//import { CustomLoaderService } from "../../custom-loader.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title$ : Observable<string>;
  userName: any;
  loginData={};

  // // Loader
  // @Input() public id: string = 'global';
  // public show: boolean;

  constructor(private commonService: CommonService,
              private router: Router,
              private elRef: ElementRef,
              private ref: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              private sessionTimeout: SessionTimeoutService,
             // private loaderService: CustomLoaderService
              ) {
               //this.ref.detach();

               }
  ngOnInit() {


    // Loader
   /*  this.loaderService.loaderStatus$.subscribe((response: Loader) => {
      this.show = this.id === response.id && response.status;

      console.log('this.show', this.show);
    });
    this.ref.detectChanges();
 */
    const route$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute)
    );
    const primaryRoute$ = route$.pipe(
      map(route => {
        while(route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary')
    );
    const routeData$ = primaryRoute$.pipe(mergeMap(route => route.data));
    this.title$ = routeData$.pipe(map(({ title }) => title ));
    this.title$.subscribe(title => {
      this.titleService.setTitle(title);
    });
  }

  skipToMainContent(){
    const htmlElement = this.elRef.nativeElement.querySelector('#homePage .col-12');
    console.log(htmlElement);

    if (htmlElement) {
      htmlElement.setAttribute('tabindex','0');
        htmlElement.focus();
    }
    //this.ref.detectChanges();
}
}
