import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import * as  dashboardEnum from '../student/lms-dashboard.enum';
import * as coursePlayerEnums from '../lms-imscc-player/lms-imscc-player.enum';
import { commonrequests } from "../commonHttprequest";
import { CustomLoaderService } from '../loader/custom-loader.service';

@Injectable({
  providedIn: 'root'
})

export class SearchService {

  organizationId = environment.organizationId;
  baseUrl = environment.courseAPI;
  dashboardEnum = dashboardEnum;
  coursePlayerEnums=coursePlayerEnums;
  allCourses: any =[];
  totalRecords: any;
  displayMode: number;
  courseName: string;
  packageLocation: string;
  isOptionsEmpty$: any;
  SearchText: '';

  constructor(private http: HttpClient,private router: Router, private commonrequests: commonrequests,  private loaderService: CustomLoaderService) { }
  async search(q: string) {

    let searchFilterData = {
      OrganizationId: this.organizationId,
      searchBy:"Name",
      SearchText:q,
      from: null,
      size: null,
     "currentuser":localStorage.getItem("lms.accountId"),
   }
   try{
    this.loaderService.show();
    const url = this.baseUrl + this.dashboardEnum.DashboardWebApis.getGlobalSearch + '?q=' + q;
    const res:any = await this.commonrequests.courseData(url, searchFilterData).toPromise();
      //if(res.errorCode === 200){
        this.allCourses = res.data;
        // console.log(JSON.stringify(data));
        this.totalRecords = res.data.length;
        this.SearchText = this.SearchText;
        this.loaderService.hide();
        // console.log(this.allCourses);
      //}
    }catch(error){
        this.loaderService.hide();
        if(error.status == 401) {
          localStorage.clear();
          this.router.navigate(['/login-form'])
        }
    }
  }

   /* search(q: string) {
    //this.allCourses = [];
    let searchFilterData = {
      OrganizationId: this.organizationId,
      searchBy:"Name",
      SearchText:q,
      from: null,
      size: null,
     "currentuser":localStorage.getItem("lms.accountId"),
   }
    const url = this.baseUrl + this.dashboardEnum.DashboardWebApis.getGlobalSearch + '?q=' + q;
    this.ngxUiLoaderService.start();
    this.commonrequests.courseData(url, searchFilterData)
      .subscribe((data: any) => {
        this.allCourses = data.data;
        // console.log(JSON.stringify(data));
        this.totalRecords = data.data.length;
        this.SearchText = this.SearchText;
        this.loaderService.hide();
        // console.log(this.allCourses);
      }, (error) => {
          this.loaderService.hide();
          if(error.status == 401) {
            localStorage.clear();
            this.router.navigate(['/login-form'])
          }
      });
      //return this.allCourses;
  } */
}


