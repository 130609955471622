<a class="sr-only sr-only-focusable skiptomain" href="JavaScript:void(0);">Skip to main content</a>
<div *ngIf="title$ | async as title" aria-live="assertive">
    <span aria-hidden="true" [attr.aria-label]="title"></span>
</div>
<div role="alert" aria-label="please wait until page is loading">
    <!-- <ngx-ui-loader></ngx-ui-loader> -->
    <custom-loader></custom-loader>
    <!-- <app-loader [id]="'global'"></app-loader> -->
</div>
<!-- <ng-container *ngIf="!show"> -->
<router-outlet></router-outlet>
<!-- </ng-container> -->



<!-- <app-coming-soon></app-coming-soon> -->
<!-- <app-under-maintenance></app-under-maintenance> -->