import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SearchRoutingModule } from './search-routing.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchComponent } from './search/search.component';
//import { LayoutModule } from './layout';
import { ImageCropperModule } from 'ngx-image-cropper';
import {ToastModule} from 'primeng/toast';
import { RatingModule } from 'ng-starrating';

@NgModule({
  declarations: [
      SearchBarComponent,
      SearchComponent,

  ],
  imports: [
        CommonModule,
        SearchRoutingModule,
        HttpClientModule,
        FormsModule,
        //LayoutModule,
        ImageCropperModule,
        ToastModule,
        RatingModule
      ],
  exports: [SearchBarComponent],

})
export class SearchModule {}
