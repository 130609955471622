import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
  submitted: boolean = false;
  invalidDetails: boolean = false;
  @Output() demo = new EventEmitter<string>();
  constructor(private searchService: SearchService, private router: Router) { }

  ngOnInit() {
  }

  onSubmit(f: NgForm) {
    //this.searchService.search(f.value.search);
    this.demo.emit(f.value.search);
    this.router.navigate(['search'], {queryParams: {q: f.value.search }});
    }
    search() {
     // alert("yes");
      //var itm = document.getElementById("#notificationTmpl");
    }
}
