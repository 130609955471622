import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './services/authentication.service';


@NgModule({
    declarations: [

    ],
    exports: [

    ],
    imports: [CommonModule],
    
    providers: [
        AuthenticationService
    ]
})
export class CoreModule { }
