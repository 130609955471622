import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TagInputModule } from 'ngx-chips';
import { DaysClaculationPipe } from '../_helpers/days-claculation.pipe';
import { SafeHtmlPipe } from '../_helpers/pipes/safe.pipe';
import { SafeUrlPipe } from '../_helpers/pipes/safe-url.pipe';
import { CertificateModalModule } from './certificate-modal/certificate-modal.module';

@NgModule({
  declarations: [
    DaysClaculationPipe,
    SafeHtmlPipe,
    SafeUrlPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CertificateModalModule
  ],
  exports: [
    TableModule,
    MultiSelectModule,
    CalendarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    CalendarModule,
    TableModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    ChartModule,
    TagInputModule,
    InfiniteScrollModule,
    DaysClaculationPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    CertificateModalModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]


})
export class SharedModule { }
