import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../common.service';
import { AuthenticationService } from './authentication.service';
const MINUTES_UNITL_AUTO_LOGOUT = 60 // in Minutes
const CHECK_INTERVALL = 1000 // in ms
const STORE_KEY = 'lastAction';

@Injectable()

export class SessionTimeoutService {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private commonService: CommonService,
    private AuthenticationService: AuthenticationService,
  ) {
    this.check();
    this.initListener();
    this.initInterval();
  }

  get lastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }

  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('mouseover',()=> this.reset());
      document.body.addEventListener('mouseout',() => this.reset());
      document.body.addEventListener('keydown',() => this.reset());
      document.body.addEventListener('keyup',() => this.reset());
      document.body.addEventListener('keypress',() => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        //console.log('setInterval')
        this.check();
      }, CHECK_INTERVALL);
    })
  }
  LogOut() {
    this.AuthenticationService.logoutUser();
  }
  reset() {
    this.setLastAction(Date.now());
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout &&  this.AuthenticationService.logoutUser) {
        // console.log('Logout');
        this.LogOut();
      }
    });
  }
}
