import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CustomLoaderService {

  private loaderSubject = new Subject<any>();
  private loaderState = this.loaderSubject.asObservable();
  private timeout: any;

  constructor() { }

  public getLoaderState(): Observable<any> {
    return this.loaderState;
  }

  public show(delay?: number) {
    if (delay) {
      this.timeout = setTimeout(() => this.loaderSubject.next(<any>{ show: true }), delay);
    } else {
      this.loaderSubject.next(<any>{ show: true });
    }
  }

  public hide() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.loaderSubject.next(<any>{ show: false });
  }

}
