<!-- <lm-imscc-player [manifestLocation]="manifestLocation" [cookie]="cookie" [imsccapi]="imsccapi" [usecookie]="usecookie"
 [coursetitle]="coursetitle">
</lm-imscc-player> -->

<!-- <lm-fcd-player [manifestLocation]="manifestLocation" [cookie]="cookie" [fcdapi]="fcdapi" [usecookie]="usecookie" [coursetitle]="coursetitle" [userrole]="userRole" [VisitedData]='LastVisitedData' (onTreeNodeClick)="TreeNodeClick($event)" [Notes]='allNotes'
    (onBackButtonClick)="changeRoute($event)" (pageToPrint)='takePrint($event)' (createNote)='createNewNote($event)' (getNotes)='getAllNotes($event)' (editNotes)='onEditNote($event)' (deleteNote)='onNoteDelete($event)'>
</lm-fcd-player> -->


<!-- <lm-fcd-player [config]="config" [toolConfigs]="toolConfigs"></lm-fcd-player> -->

<!-- <lm-fcd-player [config]="config" [toolConfigs]="toolConfigs" (onBackButtonClick)="changeRoute($event)"></lm-fcd-player> -->

<ng-container *ngIf="showFcdPlayer">
    <lm-fcd-player [config]="config" [toolConfigs]="toolConfigs" (onBackButtonClick)="changeRoute($event)" [userrole]="userRole" [VisitedData]='LastVisitedData' (onTreeNodeClick)="TreeNodeClick($event)"></lm-fcd-player>
</ng-container>