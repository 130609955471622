
import { Routes } from '@angular/router';
import { LmsImsccPlayerComponent } from './lms-imscc-player/lms-imscc-player.component';
//import { UnderMaintenanceComponent } from './default-pages/under-maintenance/under-maintenance.component';
import { GoogleCalendarComponent } from './google-calendar/google-calendar.component';
import { GoogleFormComponent } from './google-form/google-form.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./account/index').then(m => m.AccountModule)
  },
  //  {
  //   path: '',
  //   component: UnderMaintenanceComponent
  // },
  {
    path: 'login-form',
    loadChildren: () => import('./account/index').then(m => m.AccountModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/index').then(m => m.AdminModule)
  },
  {
    path: 'student',
    loadChildren: () => import('./student/index').then(m => m.StudentModule)
  },
  {
    path: 'lmsplayer',
    component: LmsImsccPlayerComponent
  },
  {
    path: 'lmsplayer/:courseId',
    component: LmsImsccPlayerComponent   
  },
  {
    path: 'gradebook',
    loadChildren: () => import('./gradebook/gradebook.module').then(m => m.GradebookModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./google-calendar/google-calendar.module').then(m => m.GoogleCalendarModule)
  },
  {
    path: 'google-form',
    loadChildren: () => import('./google-form/google-form.module').then(m => m.GoogleFormModule)
  }
  // {
  //   path: 'lmsplayer',
  //   loadChildren: () => import('./lms-imscc-player/lmsplayer.module').then(m => m.LmsPlayerModule)
  // }
];

